function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function eraseCookie(name) {   
  document.cookie = name+'=; Max-Age=-99999999;';  
}

if(getCookie('cookie-policy-accepted') != 'true') $(".cookie-consent-container").css('display', 'flex');

$('.cookie-consent-container .icon-times').on('click', function() {
  $(".cookie-consent-container").hide();
});

$('.cookie-consent-container .btn-agree').on('click', function() {
  setCookie('cookie-policy-accepted', 'true', 365);
  $(".cookie-consent-container").hide();
});

$('.all-user-orders-data-container').on('click', '.order-container-opener', function(){
  $(this)
    .next('.order-container')
    .stop(true, false)
    .slideToggle();
});

$('.get-GDPR-data').on('click', function() {
  $('.GDPR-data-container').toggle()
    .find('.all-user-data-table tbody').empty();

  $.ajax({
      method: "GET",
      url: "/profile/all-my-data",
  })
  .done(function( data ) {
      //My profile table filling
      var userDataMap = {
        en: {
          avatar: 'Profile picture',
          email: 'Email',
          name: 'Name',
          second_name: 'Surname',
          country: 'Country', 
          city: 'City',
          gender: 'Gender',
          phone: 'Phone number',
          billing_as: 'Billing as',
          created_at: 'Account creation date',
          updated_at: 'Last account update date',
          accepted_GDPR: 'Data collection consent'
        },
        es: {
          avatar: '',
          email: '',
          name: '',
          second_name: '',
          country: '', 
          city: '',
          gender: '',
          phone: '',
          billing_as: '',
          created_at: '',
          updated_at: '',
          accepted_GDPR: ''
        },
        it: {
          avatar: '',
          email: '',
          name: '',
          second_name: '',
          country: '', 
          city: '',
          gender: '',
          phone: '',
          billing_as: '',
          created_at: '',
          updated_at: '',
          accepted_GDPR: ''
        },
        pt: {
          avatar: '',
          email: '',
          name: '',
          second_name: '',
          country: '', 
          city: '',
          gender: '',
          phone: '',
          billing_as: '',
          created_at: '',
          updated_at: '',
          accepted_GDPR: ''
        },
        ru: {
          avatar: '',
          email: '',
          name: '',
          second_name: '',
          country: '', 
          city: '',
          gender: '',
          phone: '',
          billing_as: '',
          created_at: '',
          updated_at: '',
          accepted_GDPR: ''
        }
      };

      data.country = data.country.name;
      if(data.accepted_GDPR == 1) data.accepted_GDPR = 'Accepted';
      else data.accepted_GDPR = 'Declined';

      for(var key in userDataMap[language]) 
      {
          $('.all-user-data-table tbody')
            .append('<tr><td>'+userDataMap[language][key]+'</td><td>'+data[key]+'</td></tr>');
      }

      //company table filling
        var CompanyDataMap = {
          en: {
            company_name: 'Name',
            company_number: 'Number',
            company_address: 'Address',
            company_city: 'City',
            company_postal_code: 'Postal code', 
            company_vat_number: 'VAT',
            company_additional_info: 'Additional information',
            company_shipping_city: 'Shipping city',
            company_shipping_address: 'Shipping address',
            company_shipping_postal_code: 'Shipping postal code',
          },
          es: {
            company_name: '',
            company_number: '',
            company_address: '',
            company_city: '',
            company_postal_code: '', 
            company_vat_number: '',
            company_additional_info: '',
            company_shipping_city: '',
            company_shipping_address: '',
            company_shipping_postal_code: '',
          },
          it: {
            company_name: '',
            company_number: '',
            company_address: '',
            company_city: '',
            company_postal_code: '', 
            company_vat_number: '',
            company_additional_info: '',
            company_shipping_city: '',
            company_shipping_address: '',
            company_shipping_postal_code: '',
          },
          pt: {
            company_name: '',
            company_number: '',
            company_address: '',
            company_city: '',
            company_postal_code: '', 
            company_vat_number: '',
            company_additional_info: '',
            company_shipping_city: '',
            company_shipping_address: '',
            company_shipping_postal_code: '',
          },
          ru: {
            company_name: '',
            company_number: '',
            company_address: '',
            company_city: '',
            company_postal_code: '', 
            company_vat_number: '',
            company_additional_info: '',
            company_shipping_city: '',
            company_shipping_address: '',
            company_shipping_postal_code: '',
          },
      };

      if(data.company && data.company.length) {
        for(var key in CompanyDataMap[window.language]) 
        {
            $('.all-user-company-data-table tbody')
              .append('<tr><td>'+CompanyDataMap[window.language][key]+'</td><td>'+data.company[key]+'</td></tr>');
        }
      }

      //orders table filling
      var OrderDataMap = {
        en: {
          additional_tax: 'Additional tax',
          additional_tax_name: 'Additional tax name',
          amount: 'Amount',
          amount_without_tax: 'Amount without tax',
          created_at: 'Created at', 
          currency: 'Currency',
          email: 'Email',
          email_sent: 'Email sent',
          order_id_customised: 'Order id customised',
          paid: 'Paid',
          payment_method: 'Payment method',
          payment_type: 'Payment type',
          product_name: 'Product name',
          product_price: 'Product price',
          status: 'Status',
          tax_amount: 'Tax amount',
          tax_percent: 'Tax percent',
          training_id: 'Training id',
          type: 'Type',
          updated_at: 'Updated at'
        },
        es: {
          additional_tax: '',
          additional_tax_name: '',
          amount: '',
          amount_without_tax: '',
          created_at: '', 
          currency: '',
          email: '',
          email_sent: '',
          order_id_customised: '',
          paid: '',
          payment_method: '',
          payment_type: '',
          product_name: '',
          product_price: '',
          status: '',
          tax_amount: '',
          tax_percent: '',
          training_id: '',
          type: '',
          updated_at: ''
        },
        it: {
          additional_tax: '',
          additional_tax_name: '',
          amount: '',
          amount_without_tax: '',
          created_at: '', 
          currency: '',
          email: '',
          email_sent: '',
          order_id_customised: '',
          paid: '',
          payment_method: '',
          payment_type: '',
          product_name: '',
          product_price: '',
          status: '',
          tax_amount: '',
          tax_percent: '',
          training_id: '',
          type: '',
          updated_at: ''
        },
        pt: {
          additional_tax: '',
          additional_tax_name: '',
          amount: '',
          amount_without_tax: '',
          created_at: '', 
          currency: '',
          email: '',
          email_sent: '',
          order_id_customised: '',
          paid: '',
          payment_method: '',
          payment_type: '',
          product_name: '',
          product_price: '',
          status: '',
          tax_amount: '',
          tax_percent: '',
          training_id: '',
          type: '',
          updated_at: ''
        },
        ru: {
          additional_tax: '',
          additional_tax_name: '',
          amount: '',
          amount_without_tax: '',
          created_at: '', 
          currency: '',
          email: '',
          email_sent: '',
          order_id_customised: '',
          paid: '',
          payment_method: '',
          payment_type: '',
          product_name: '',
          product_price: '',
          status: '',
          tax_amount: '',
          tax_percent: '',
          training_id: '',
          type: '',
          updated_at: ''
        }
    };

    if(data.orders) {
      for(var i = 0; i < data.orders.length; i++)
      {
        var currOrder = data.orders[i];
  
        $('.all-user-orders-data-container')
          .append(''+
            '<p class="order-container-opener">'+currOrder.product_name+'</p>'+
            '<div class="order-container"></div>');
  
        var $newRow = $('.all-user-orders-data-container .order-container:last-child');
        
        for(var key in OrderDataMap[language]) 
        {
            $newRow.append(''+
              '<div class="order">'+
                '<span>'+OrderDataMap[language][key]+'</span>'+
                '<span>'+currOrder[key]+'</span>'+
              '</div>'
            );
        }
      }
    }

    //company table filling
    var TrainerDataMap = {
      en: {
        avatar: 'Avatar',
        created_at: 'Created at',
        email: 'Email',
        name: 'Name',
        second_name: 'Second name', 
        updated_at: 'Updated at',
      },
      es: {
        avatar: '',
        created_at: '',
        email: '',
        name: '',
        second_name: '', 
        updated_at: '',
      },
      pt: {
        avatar: '',
        created_at: '',
        email: '',
        name: '',
        second_name: '', 
        updated_at: '',
      },
      it: {
        avatar: '',
        created_at: '',
        email: '',
        name: '',
        second_name: '', 
        updated_at: '',
      },
      ru: {
        avatar: '',
        created_at: '',
        email: '',
        name: '',
        second_name: '', 
        updated_at: '',
      }
    };
    
    if(data.trainer && data.trainer.length) {
      for(var key in TrainerDataMap[language]) 
      {
          $('.all-user-trainer-data-table tbody')
            .append('<tr><td>'+TrainerDataMap[language][key]+'</td><td>'+data.trainer[key]+'</td></tr>');
      }
    }
  });
});

$(function(){
  $('video').attr('poster', '/public/images/loading.gif');
});